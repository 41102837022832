enum ActionItem {
  base = 'action-item',
  create = ActionItem.base + '/create',
  update = ActionItem.base + '/update',
  delete = ActionItem.base + '/delete',
  setState = ActionItem.base + '/set-state',
}

enum AIAssistant {
  base = 'ai-wanna-be',
  goalFromFeedback = AIAssistant.base + '/goal-from-feedback',
  goalFromPRCycle = AIAssistant.base + '/goal-from-pr-cycle',
}

enum Auth {
  base = 'auth',
  confirmEmailToken = Auth.base + '/confirm-email-token',
  confirmRecover = Auth.base + '/confirm-recover',
  create = Auth.base + '/create-from-invitation',
  generateNonce = Auth.base + '/generate-nonce',
  login = Auth.base + '/login',
  loginWithMicrosoftSSO = Auth.base + '/login-with-microsoft-sso',
  requestAccountActivationEmail = Auth.base + '/request-email/activate-account',
  requestResetPasswordEmail = Auth.base + '/request-email/reset-password',
  requestUnlockEmail = Auth.base + '/request-email/unlock-profile',
  resetPassword = Auth.base + '/reset-password',
  updateAuthMode = Auth.base + '/update-auth-mode',
  loginWithGoogleOAuth = 'oauth-google/login',
}

enum Calendar {
  base = 'calendar-events',
  create = Calendar.base + '/create-event',
  day = Calendar.base + '/day',
  month = Calendar.base + '/month',
}

enum Feed {
  base = 'feed',
  posts = Feed.base + '/posts',
  leaderboard = Feed.base + '/leaderboard',
}

enum Feedback {
  base = 'feedback',
  create = Feedback.base + '/create',
  give = Feedback.base + '/give',
  request = Feedback.base + '/request',
  answer = Feedback.base + '/answer',
  delete = Feedback.base + '/delete',
  list = Feedback.base + '/list',
  listForUser = Feedback.base + '/list-for-user',
  answerSurvey = Feedback.base + '/answer-survey',
  getFeedbackByCycle = Feedback.base + '/by-pr-cycle',
}

enum FeedbackTemplate {
  base = 'feedback-template',
  create = FeedbackTemplate.base + '/create',
  update = FeedbackTemplate.base + '/update',
  list = FeedbackTemplate.base + '/list',
  downloadReport = FeedbackTemplate.base + '/download-report',
}

enum Goals {
  base = 'goals',
  latest = Goals.base + '/latest',
  forUser = Goals.base + '/for-user',
  listByIds = Goals.base + '/list-by-ids',
  requestedGoals = Goals.base + '/requested-goals',
  create = Goals.base + '/create',
  createFor = Goals.base + '/create-for',
  update = Goals.base + '/update',
  updateField = Goals.base + '/update-field',
  setState = Goals.base + '/set-state',
  deleteResource = 'resources/delete/goal',
  uploadResource = 'resources/upload/goal',
  deleteContributor = Goals.base + '/delete-contributor',
  updateSkills = 'goal-skills/update',
}

enum GoalNotes {
  base = 'goal-notes',
  create = GoalNotes.base + '/create',
}

enum Mail {
  base = 'mail',
  validateToken = Mail.base + '/validate-token',
}

enum Meeting {
  base = 'meeting',
  create = Meeting.base + '/create',
  update = Meeting.base + '/update',
  updateField = Meeting.base + '/update-field',
  allowGoalsReview = Meeting.base + '/allow-goals-review',
  disableGoalsReview = Meeting.base + '/disable-goals-review',
  getConferenceLink = Meeting.base + '/get-conference-link',
  addAttendee = Meeting.base + '/add-attendee',
  removeAttendee = Meeting.base + '/remove-attendee',
  addNote = Meeting.base + '/add-note',
  selectGoals = Meeting.base + '/select-goals',
  delete = Meeting.base + '/delete',
}

enum MeetingQuestion {
  base = 'meeting-question',
  answer = MeetingQuestion.base + '/answer',
  create = MeetingQuestion.base + '/create',
  delete = MeetingQuestion.base + '/delete',
  deleteAnswer = MeetingQuestion.base + '/delete-answer',
  duplicate = MeetingQuestion.base + '/duplicate',
  update = MeetingQuestion.base + '/update',
}

enum Notification {
  base = 'notifications',
  markAsRead = Notification.base + '/mark-as-read',
  removeAll = Notification.base + '/remove-all',
  fetch = Notification.base + '/fetch',
}

enum Objectives {
  base = 'objectives',
  create = Objectives.base + '/create',
  update = Objectives.base + '/update',
  updateField = Objectives.base + '/update-field',
  deleteResource = 'resources/delete/objective',
  deleteContributor = Objectives.base + '/delete-contributor',
  uploadResource = 'resources/upload/objective',
  updateSkills = 'objective-skills/update',
  linkGoal = Objectives.base + '/link-goal',
}

enum Organization {
  base = 'organization',
  create = Organization.base + '/create',
}

enum PerformanceReview {
  base = 'performance-review',
  addStepToPRTemplate = PerformanceReview.base + '/add-step-to-pr-template',
  aggregatedPR = PerformanceReview.base + '/aggregated-pr',
  aggregatedPRSummaries = PerformanceReview.base + '/aggregated-pr-summaries',
  createTemplate = PerformanceReview.base + '/create-template',
  createCycles = PerformanceReview.base + '/create-cycles',
  createCycleForUser = PerformanceReview.base + '/create-cycle-for-user',
  deleteTemplate = PerformanceReview.base + '/delete-template',
  getPRsForUsers = PerformanceReview.base + '/get-prs-for-users',
  getPeerReviews = PerformanceReview.base + '/get-peer-reviews',
  latestCycle = PerformanceReview.base + '/latest-cycle',
  latestTemplate = PerformanceReview.base + '/latest-template',
  prCycleAssessment = PerformanceReview.base + '/pr-cycle-assessment',
  refreshPRSummary = PerformanceReview.base + '/refresh-pr-summary',
  requestPeerReviews = PerformanceReview.base + '/request-peer-reviews',
  removeStepFromPRTemplate = PerformanceReview.base +
    '/remove-step-from-pr-template',
  setPRTemplate = PerformanceReview.base + '/set-pr-template',
}

enum Skills {
  base = 'skills',
  update = Skills.base + '/update',
  filterByTitle = Skills.base + '/filter-by-title',
}

enum Subtask {
  base = 'action-item-subtask',
  create = Subtask.base + '/create',
  update = Subtask.base + '/update',
  delete = Subtask.base + '/delete',
}

enum Team {
  base = 'team',

  /* CREATE */
  create = Team.base + '/create',
  invite = Team.base + '/invite',

  /* READ */
  getAllUsers = Team.base + '/get-all-users',
  teamMembers = Team.base + '/team-members',
  usersCount = Team.base + '/users-count',

  /* UPDATE */
  update = Team.base + '/update',

  /* DELETE */
  delete = Team.base + '/delete',
}

enum TeamMetrics {
  base = 'team-metrics',
  list = TeamMetrics.base + '/list',
  sync = TeamMetrics.base + '/sync',
  goalsPerTeam = TeamMetrics.base + '/goals-per-team',
}

enum User {
  base = 'user',
  getProfileData = User.base + '/get-profile-data',
  giveConsent = User.base + '/give-consent',
  grantPermissions = User.base + '/grant-permissions',
  setReportsTo = User.base + '/set-reports-to',
  updateProfile = User.base + '/update-profile',
}

enum UserMetrics {
  base = 'user-metrics',
  goalsStatusPerUser = UserMetrics.base + '/goals-status-per-user',
  sync = UserMetrics.base + '/sync',
  lastMet = UserMetrics.base + '/last-met',
}

enum UserProfile {
  base = 'user-profile',
  deleteUser = UserProfile.base + '/delete-user',
}

const Endpoints = {
  ActionItem,
  AIAssistant,
  Auth,
  Calendar,
  Feed,
  Feedback,
  FeedbackTemplate,
  Goals,
  GoalNotes,
  Mail,
  Meeting,
  MeetingQuestion,
  Notification,
  Objectives,
  Organization,
  PerformanceReview,
  Skills,
  Subtask,
  Team,
  TeamMetrics,
  User,
  UserMetrics,
  UserProfile,
};

export { Endpoints };
