import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable, signal, WritableSignal } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthenticationResult } from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { Observable, catchError, tap } from 'rxjs';
import { AuthApiService } from 'src/app/auth/api/auth-api.service';
import { TeamApiService } from 'src/app/team/api/team.api.service';
import { AppRoutes } from '../enums/app-routes.enum';
import { AuthProvider } from '../enums/auth.enum';
import { AuthMode } from '../enums/permissions.enum';
import { LocalStorage } from '../enums/store-constants.enum';
import { OrganizationData } from '../interfaces/organization.interface';
import {
  LoginData,
  PasswordForgot,
  PasswordReset,
  UnlockAccount,
  UserLoginResponse,
} from '../interfaces/user/auth.interface';
import {
  CreateUserFromInvitation,
  UserData,
} from '../interfaces/user/user.interface';
import { TranslatePipe } from '../pipes/translate.pipe';
import {
  AuthActions,
  AuthModeActions,
  authActionLogOut,
} from '../store/auth/auth.actions';
import { schedulerActionRemoveAll } from '../store/calendar-event/calendar-event.actions';
import { removeAllFeedbackTemplates } from '../store/feedback-template/feedback-template.actions';
import { removeAllFeedbacks } from '../store/feedback/feedback.actions';
import { removeAllGoals } from '../store/goals/goals.actions';
import { removeAllMeetings } from '../store/meetings/meeting.actions';
import { removeAllEmployees } from '../store/organization/organization.actions';
import { TeamsActions, removeAllTeams } from '../store/team/team.actions';
import { MicrosoftSSOService } from './sso/microsoft-sso.service';
import { removeAllPRCycles } from '../store/performance-review/performance-review.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private static readonly authProvider = signal(AuthProvider.Google);

  private readonly authSuccessful: WritableSignal<Boolean> = signal(false);

  private websiteRegexSequence = '([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,30})';

  public websitePattern = `^${this.websiteRegexSequence}$`;
  public emailPattern = `^([a-zA-Z0-9_\\-\\.]+)@${this.websiteRegexSequence}$`;
  public passwordPattern = '^[a-zA-Z0-9!?@#$%\\^&\\*\\(\\)_\\-\\.,+=]+$';

  public passwordValidators = [
    Validators.required,
    Validators.pattern,
    Validators.minLength(8),
    Validators.maxLength(30),
    this.passwordStrengthValidator(),
  ];

  get isAuthSuccessful() {
    return this.authSuccessful();
  }

  static get getAuthProvider() {
    return AuthService.authProvider();
  }

  constructor(
    private apiService: AuthApiService,
    private teamApiService: TeamApiService,
    private snackBar: MatSnackBar,
    private store: Store,
    private translatePipe: TranslatePipe,
    private router: Router,
    private MicrosoftSSOService: MicrosoftSSOService,
  ) {}

  public afterLogin(response: UserLoginResponse) {
    {
      if (response.status && response.status !== HttpStatusCode.Ok) {
        throw new HttpErrorResponse({
          error: response.message,
        });
      }

      localStorage.setItem(
        LocalStorage.organization,
        JSON.stringify(response.organization),
      );

      localStorage.setItem(LocalStorage.user, JSON.stringify(response.user));

      this.store.dispatch(
        AuthActions.saveToken({ token: response.access_token }),
      );

      this.store.dispatch(
        AuthActions.saveUserProfile({ userProfileData: response.user }),
      );

      this.teamApiService.getTeamMembers().subscribe((teams) => {
        this.store.dispatch(TeamsActions.loadTeams({ teams }));
      });

      this.setAuthMode(response.authMode);
    }
  }

  public static getUser(): UserData {
    const localStorageUser = localStorage.getItem(LocalStorage.user);

    try {
      return JSON.parse(localStorageUser);
    } catch (error) {
      return null;
    }
  }

  public static acknowledgeConsent(data: string) {
    const user: UserData = {
      ...AuthService.getUser(),
      gaveConsent: data,
    };

    localStorage.setItem(LocalStorage.user, JSON.stringify(user));
  }

  public static getOrganization(): OrganizationData {
    return JSON.parse(localStorage.getItem(LocalStorage.organization));
  }

  static setTokensLeft(tokensLeft: number) {
    const user = this.getUser();

    localStorage.setItem(
      LocalStorage.user,
      JSON.stringify({
        ...user,
        tokensLeft,
      }),
    );
  }

  public authenticate(loginData: LoginData): Observable<UserLoginResponse> {
    return this.apiService.logIn(loginData).pipe(
      tap((response) => this.afterLogin(response)),
      catchError((e) => {
        throw e;
      }),
    );
  }

  public authenticateWithMicrosoftSSO(
    authResult: AuthenticationResult,
  ): Observable<UserLoginResponse> {
    return this.apiService.logInWithMicrosoftSSO(authResult).pipe(
      tap((response) => this.afterLogin(response)),
      catchError((e) => {
        throw e;
      }),
    );
  }

  public resetPassword(
    passwordReset: PasswordReset,
    token: string,
  ): Observable<UserLoginResponse> {
    return this.apiService.resetPassword(passwordReset, token);
  }

  public forgotPassword(
    passwordForgot: PasswordForgot,
  ): Observable<UserLoginResponse> {
    return this.apiService.forgotPassword(passwordForgot);
  }

  public unlockAccount(unlockAccount: UnlockAccount): Observable<string> {
    return this.apiService.unlockAccount(unlockAccount);
  }

  public requestEmailToConfirmAccount(
    activateEmail: UnlockAccount,
  ): Observable<UserLoginResponse> {
    return this.apiService.requestEmailToConfirmAccount(activateEmail);
  }

  public createUser(
    createUserData: CreateUserFromInvitation,
  ): Observable<UserData> {
    return this.apiService.createUser(createUserData);
  }

  public createOrganization(createOrganizationData: OrganizationData) {
    return this.apiService.signUp(createOrganizationData);
  }

  public validateToken(tokenParam?: string): boolean {
    const token = tokenParam || localStorage.getItem(LocalStorage.jwtToken);

    if (!token) {
      this.snackBar.open(this.translatePipe.transform('pleaseLogIn'));

      return false;
    }

    return true;
  }

  public passwordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      const hasUpperCase = /[A-Z]+/.test(value);

      const hasLowerCase = /[a-z]+/.test(value);

      const hasNumeric = /[0-9]+/.test(value);

      const hasSpecialCharacters = /[!@#$%\\^&\\*\\(\\)_\\-\\.,+=]+/.test(
        value,
      );

      if (!hasUpperCase) {
        return {
          passwordStrength: 'Should contain at least one upper case letter',
        };
      }

      if (!hasLowerCase) {
        return {
          passwordStrength: 'Should contain at least one lower case letter',
        };
      }

      if (!hasNumeric) {
        return { passwordStrength: 'Should contain at least one digit' };
      }

      if (!hasSpecialCharacters) {
        return {
          passwordStrength:
            'Should contain at least one special character [ !@#$%^&*)()_-.,+= ]',
        };
      }

      return null;
    };
  }

  public getPasswordErrorMessage(form: FormGroup): string {
    const errors = form.get('password')?.errors as any;

    if (!errors) {
      return '';
    }

    if (errors.required) {
      return 'Password is required';
    }

    if (errors.minlength) {
      return `Password should be between ${errors.minlength.requiredLength} and 30 characters long`;
    }

    if (errors.passwordStrength) {
      return errors.passwordStrength;
    }

    return 'Password is invalid';
  }

  public generateNonce(): Observable<string> {
    return this.apiService.generateNonce();
  }

  public logOut(options: { fragment?: string } = {}): void {
    this.store.dispatch(authActionLogOut());
    this.store.dispatch(schedulerActionRemoveAll());
    this.store.dispatch(removeAllFeedbacks());
    this.store.dispatch(removeAllFeedbackTemplates());
    this.store.dispatch(removeAllGoals());
    this.store.dispatch(removeAllMeetings());
    this.store.dispatch(removeAllEmployees());
    this.store.dispatch(removeAllTeams());
    this.store.dispatch(removeAllPRCycles());

    if (this.MicrosoftSSOService.getAuthStatus()) {
      this.MicrosoftSSOService.logout();
      localStorage.clear();
    } else {
      localStorage.clear();

      this.router.navigate([AppRoutes.Auth.base, AppRoutes.Auth.login], {
        fragment: options.fragment,
      });
    }
  }

  public authFailed() {
    this.authSuccessful.set(false);
  }

  private setAuthMode(mode: AuthMode) {
    localStorage.setItem(LocalStorage.authMode, mode || AuthMode.Personal);

    this.store.dispatch(
      AuthModeActions.setMode({
        mode,
      }),
    );
  }
}
