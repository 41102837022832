import { NgModule } from '@angular/core';
import { CountChildrenOverflowDirective } from './count-children-overflow.directive';
import { IfPermissionsDirective } from './if-permissions.directive';
import { RemoveDefaultStyleDirective } from './remove-default-style.directive';
import { SetScrollTopDirective } from './set-scroll-top.directive';

@NgModule({
  declarations: [
    IfPermissionsDirective,
    CountChildrenOverflowDirective,
    RemoveDefaultStyleDirective,
    SetScrollTopDirective,
  ],
  exports: [
    IfPermissionsDirective,
    CountChildrenOverflowDirective,
    RemoveDefaultStyleDirective,
    SetScrollTopDirective,
  ],
})
export class SharedDirectivesModule {}
