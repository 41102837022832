export enum LocalStorage {
  jwtToken = 'access_token',
  user = 'user',
  organization = 'organization',
  disabledRoutes = 'disabledRoutes',
  gdpr_ccpa = 'gdpr_ccpa',
  verified = 'verified',
  authMode = 'authMode',
  providerLogin = 'providerLogin',
}
