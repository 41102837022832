export const environment = {
  production: false,
  title: 'OneSyncs Stage',
  apiURL: 'https://pmm3000-stage-api.lm.r.appspot.com/v1',
  baseURL: 'https://stage.onesyncs.com',
  translationsReady: false,
  slackClientID: '5900163349600.6151391873399',
  microsoftSSO: {
    clientId: '2565fd56-f236-413a-a1c1-d3b0ecd896eb',
    cloudInstanceId: 'https://login.microsoftonline.com',
    tenantId: '13b0fb0b-80bf-4414-a678-0832ce07ca03',
    redirectURI: 'https://stage.onesyncs.com',
    graphEndpoint: 'https://graph.microsoft.com/v1.0/me',
  },
  googleOAuth: {
    clientId:
      '749652266279-38bss0bpugmuluoejd99objj1gkjpe76.apps.googleusercontent.com',
    redirectURI: 'https://stage.onesyncs.com/auth/google-oauth',
    endpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
    scope:
      'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar.events',
  },
};
