import { AfterViewInit, Directive, ElementRef, inject } from '@angular/core';

@Directive({
  selector: '[removeDefaultStyles]',
  standalone: false,
})
export class RemoveDefaultStyleDirective implements AfterViewInit {
  private readonly el = inject(ElementRef);

  public ngAfterViewInit(): void {
    this.el.nativeElement.style = '';
  }
}
