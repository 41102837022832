export enum IconType {
  'add-circle' = 'add-circle',
  'add-square' = 'add-square',
  'arrow-down' = 'arrow-down',
  'arrow-left' = 'arrow-left',
  'arrow-right' = 'arrow-right',
  'arrow-up' = 'arrow-up',
  'assess' = 'assess',
  'calendar-plus' = 'calendar-plus',
  'calendar-week-view' = 'calendar-week-view',
  'call-chat' = 'call-chat',
  'check' = 'check',
  'chevron-left' = 'chevron-left',
  'chevron-right' = 'chevron-right',
  'clock-circle' = 'clock-circle',
  'close-alt' = 'close-alt',
  'close-circle' = 'close-circle',
  'close-square' = 'close-square',
  'cloud-upload' = 'cloud-upload',
  'code' = 'code',
  'collaboration' = 'collaboration',
  'copy' = 'copy',
  'copy-link' = 'copy-link',
  'current-cycle' = 'current-cycle',
  'danger-triangle' = 'danger-triangle',
  'diskette' = 'diskette',
  'download-file' = 'download-file',
  'dropdown-bold' = 'dropdown-bold',
  'dropdown' = 'dropdown',
  'external' = 'external',
  'eye-closed' = 'eye-closed',
  'feed' = 'feed',
  'file' = 'file',
  'fine-tunning' = 'fine-tunning',
  'flag' = 'flag',
  'flip-vertical' = 'flip-vertical',
  'focus-current' = 'focus-current',
  'graph-up' = 'graph-up',
  'hand-stars' = 'hand-stars',
  'incognito' = 'incognito',
  'info-square' = 'info-square',
  'list-view' = 'list-view',
  'maximize-square' = 'maximize-square',
  'minimize-square' = 'minimize-square',
  'more' = 'more',
  'notes' = 'notes',
  'onboarding' = 'onboarding',
  'one-to-one' = 'one-to-one',
  'outgoing-call' = 'outgoing-call',
  'paper-plane' = 'paper-plane',
  'pen-underline' = 'pen-underline',
  'performance-review' = 'performance-review',
  'pie-chart' = 'pie-chart',
  'question' = 'question',
  'radio-translation' = 'radio-translation',
  'restart-circle' = 'restart-circle',
  'restart' = 'restart',
  'search' = 'search',
  'square-forward' = 'square-forward',
  'star' = 'star',
  'stars' = 'stars',
  'three-dots-horizontal' = 'three-dots-horizontal',
  'time-history' = 'time-history',
  'transfer-horizontal' = 'transfer-horizontal',
  'trash-bin-minimalistic' = 'trash-bin-minimalistic',
  'trash-bin-outlined' = 'trash-bin-outlined',
  'user-check' = 'user-check',
  'user-circle' = 'user-circle',
  'user-id' = 'user-id',
  'user-speak' = 'user-speak',
  'users-group-rounded' = 'users-group-rounded',
}
