import {
  AfterViewInit,
  Directive,
  ElementRef,
  inject,
  input,
} from '@angular/core';

@Directive({
  selector: '[setScrollTop]',
  standalone: false,
})
export class SetScrollTopDirective implements AfterViewInit {
  private readonly el = inject(ElementRef);

  public readonly targetClass = input.required<string>();
  public readonly scrollTop = input.required<number>();

  public ngAfterViewInit(): void {
    this.setScrollTop();
  }

  private setScrollTop() {
    const child = this.el.nativeElement.querySelector(`.${this.targetClass()}`);

    if (!child) {
      return;
    }

    child.scrollTop = this.scrollTop();
  }
}
